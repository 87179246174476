.spinner {
    z-index: 5;
    display: block;
  }

  %loader-circle {
    display: inline-flex;
    width: 30%;
    height: 30%;
    border-radius: 100%;
    background-color: var(--loader-color);
    animation: three var(--animation-duration) infinite ease-in-out both;
  }
  
  .threes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--loader-size);
    height: var(--loader-size);
  
    &_three {
      @extend %loader-circle;
      animation-delay: -320ms;
  
      &_1 {
        @extend %loader-circle;
        animation-delay: -160ms;
      }
  
      &_2 {
        @extend %loader-circle;
      }
    }
  }
  
  @keyframes three {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  