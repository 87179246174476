.tabs {
  height: 7rem;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,.3);
  flex-shrink: 0;
  padding: 0 2.5rem;
  &__list {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__item {
    list-style: none;
    margin: 0 2rem;
    font-size: 1.7rem;
    cursor: pointer;
    border-bottom: solid 2px rgba(255,255,255,0);
    transition: all .2s;

    &--selected {
      border-bottom: solid 2px var(--color-primary);
      font-weight: 500;
    }

    &:hover {
         border-bottom-color: var(--color-primary);
    }
  }
}
