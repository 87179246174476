//@import 'sass/variables.scss');

.barChart {
  &-info-value {
    fill: var(--area-chart-info-value);
  }

  &-info-label {
    fill: var(--area-chart-info-value);
  }

  &-secondary-info-marker {
    stroke: var(--area-chart-secondary-info-marker);
  }

  &-primary-info-marker {
    stroke: var(--area-chart-primary-info-marker);
  }

  &-secondary {
    stop-color: var(--area-chart-secondary);
  }

  &-primary {
    stop-color: var(--area-chart-primary);
  }

  &-forecast {
    stop-color: var(--area-chart-forecast);
  }

  &-primary-line {
    stroke: var(--area-chart-primary-line);
  }

  &-secondary-line {
    stroke: var(--area-chart-secondary-line);
  }

  &-forecast-line {
    stroke: var(--area-chart-forecast-line);
  }

  &-tick-line {
    stroke: var(--area-chart-tick-line);
  }

  &-focus-primary-marker {
    fill: var(--area-chart-focus-primary-marker-fill);
    stroke: var(--area-chart-focus-primary-marker-stroke);
  }

  &-focus-secondary-marker {
    fill: var(--area-chart-focus-secondary-marker-fill);
  }

  &-focus-current-value {
    fill: var(--area-chart-focus-current-value);
  }

  &-tick-value {
    fill: var(--area-chart-tick-value);
  }
}
