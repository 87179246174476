:root {
  --area-chart-info-label: #939DB2;
  --area-chart-info-value: #fff;
  --area-chart-primary: #007DB8;
  --area-chart-primary-line: #007DB8;
  --area-chart-primary-info-marker: #007DB8;
  --area-chart-secondary: rgb(216,216,216);
  --area-chart-secondary-line: rgb(216,216,216);
  --area-chart-secondary-info-marker: rgb(216,216,216);
  --area-chart-forecast: #032e66;
  --area-chart-forecast-line: #007DB8;
  --area-chart-tick-line: #fff;
  --area-chart-current-divider-line: inherit;
  --area-chart-focus-primary-marker-fill: #007DB8;
  --area-chart-focus-primary-marker-stroke: #007DB8;
  --area-chart-focus-secondary-marker-fill: rgb(216,216,216);
  --area-chart-focus-current-value: #273036;
  --area-chart-axis-line: #cccccc;
  --area-chart-tick-value: #242424;
}

.chart {
  font-size: 16px;
  font-family: 'Lato';
}

.chart svg {
  display: block;
}

.chart text {
  fill: var(--area-chart-info-label);
}

.chart .info-label {
  font-weight: 500;
  font-size: .9em;
  // text-transform: capitalize;
  fill: var(--area-chart-tick-value);
}

.chart .info-value {
  font-size: 36px;
  font-weight: 800;
  color: var(--area-chart-info-value);
}

.chart .info-value-current {
  fill: var(--area-chart-primary-info-marker);
}

.chart .up-arrow {
  fill: #0176bb;
}

.chart .down-arrow {
  fill: #e85160;
}

.chart .no-change {
  fill: #fff;
}

.chart .axis-line {
  stroke: #ccc;
}

.chart .tick-value {
  font-size: 12px;
  fill: var(--area-chart-tick-value);
  fill-opacity: 0.3;
  font-weight: bold;
}

.chart .tick-value.selected {
  fill-opacity: 0.8;
}

.chart .previous-info-marker {
  fill: none;
  stroke: var(--area-chart-secondary-info-marker);
  stroke-width: 2px;
}

.chart .current-info-marker {
  fill: none;
  stroke: var(--area-chart-primary-info-marker);
  stroke-width: 2px;
}

.chart .y1-info-marker {
  fill: none;
  stroke-width: 2px;
}

.chart .y2-info-marker {
  fill: none;
  stroke-width: 2px;
}

.chart .focus-previous-marker {
  fill: var(--area-chart-focus-secondary-marker-fill);
}

.chart .focus-current-marker {
  fill: var(--area-chart-focus-primary-marker-fill);
  stroke: var(--area-chart-focus-primary-marker-stroke);
  stroke-width: 3px;
}

.chart .focus-current-value {
  font-size: 0.7em;
  font-weight: bold;
}

.focus-opaque-overlay {
  fill: #fff;
  fill-opacity: 0.3;
}

/* Chart */
.zero-line {
  stroke: #000;
  stroke-width: 1px;
  stroke-dasharray: 2;
}

.chart .previous-line {
  fill: none;
  stroke: var(--area-chart-secondary-line);
  stroke-width: 3px;
}

.chart .current-line {
  fill: none;
  stroke: var(--area-chart-primary-line);
  stroke-width: 3px;
}

.chart .current-divider-line {
  stroke: var(--area-chart-current-divider-line);
  stroke-width: 1.5px;
}

.chart .predict-line {
  fill: none;
  stroke: var(--area-chart-forecast-line);
  stroke-width: 3px;
  stroke-dasharray: 6;
}

.chart .tick-line {
  stroke: var(--area-chart-tick-line);
  stroke-width: 0.25px;
}

.chart .focus-y1-value {
  font-size: 0.7em;
  font-weight: bold;
}

.chart .focus-y2-marker {
  fill: var(--area-chart-focus-primary-marker-fill);
  stroke: var(--area-chart-focus-secondary-marker-fill);
  stroke-width: 3px;
}

.focus-opaque-overlay {
  fill: #fff;
  fill-opacity: 0.3;
}

.chart .y2-line {
  fill: none;
  stroke: var(--area-chart-secondary-line);
  stroke-width: 3px;
}

.chart .chart-past {
  stop-color: var(--area-chart-secondary);
}

.chart .chart-current {
  stop-color: var(--area-chart-primary);
}

.chart .chart-forecast {
  stop-color: var(--area-chart-forecast);
}
