:root {
    --color-primary: #3ad4a7;
    --color-grey: rgb(36,36,36);
    --color-grey-light: rgb(233,233,233);
    --color-grey-light2: rgb(248, 248, 248);
    --color-grey2: #ccc;

    --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
    --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);
    --loader-color: var(--color-primary);
    --spinner-overlay-bgcolor: rgba(#fff, 0.7);
    --animation-duration: 1.5s;
    --loader-size: 3rem;
    
}

* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

}

body {
    font-family: 'Manrope', sans-serif;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0;
    color: var(--color-grey);
    font-size: 1.6rem;
    // min-height: 100vh;
    // width: 610px;
    width: 100%;
    // margin: 2rem auto;
    background-color: white;
    //background-color: #aaa;
    // height: 89rem;
}

.body {
    background-color: #fff;
    min-height: 60rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100vh;
}

a:link, a:focus, a:visited, a:active {
    text-decoration: none;
    color: var(--color-grey);
}
