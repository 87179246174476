.client_number {
  font-size: 1.4rem;
  margin: 3rem 2rem;
}

.client_info {
  flex: 1;
  margin-bottom: 4rem;
}

.shareholder {
  display: flex;
  margin: 1.6rem;
  border-top: solid 1px #ccc;
  padding-top: 1rem;
  font-size: 1.4rem;

  &__name {
    width: 100%;
  }

  &__value {
    font-weight: 500;
  }
}

.director {
  margin: 1.6rem;
  border-top: solid 1px #ccc;
  padding-top: 1rem;
  font-size: 1.4rem;

  &__name {
    width: 100%;
  }

  &__value {
    font-weight: 500;
    margin-bottom: 1rem;
  }
}

.search_filter {
  margin: 2.2rem 1.4rem 0 1.4rem;
  display: flex;
  align-items: center;
  & > div:first-child {
    margin-right: auto;
    height: 3.6rem;
  }
}
