.client_hc {
  flex: 1;
  margin-bottom: 4rem;
  // min-height: 65rem;
}

.view {
  text-align: right;
  display: flex;
  margin: 2.5rem 1.5rem;
  font-size: 1.4rem;

  &__label {
    margin-left: auto;
  }

  & > div:not(:last-child) {
    margin-right: 1.5rem;
  }

  &__btn {
    outline: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    background: none;
    font-weight: normal;
    cursor: pointer;

    &--selected {
      color: var(--color-primary);
      font-weight: 500;
    }
  }
}

.hc_table {
  width: 100%;
  padding: 0 1.5rem;
  margin-bottom: 4.5rem;
  border-spacing: 0;

  & > thead {
    background-color: rgba(26, 122, 93, 0.1);

    & th {
      text-align: left;
    }
  }

  & td,
  & th {
    font-weight: 500;
    font-size: 1.4rem;
    padding: 1.5rem 1rem;
  }

  & td:not(:last-child),
  & th:not(:last-child) {
    border-right: solid 1px rgb(207, 209, 213);
  }

  & > tbody > tr:nth-child(even) {
    background-color: rgb(248, 248, 248);
  }

  & > tbody > tr > td:not(:first-child) {
    text-align: right;
  }

  &__trend {
    display: flex;
    justify-content: center;
    & > img {
      width: 24px;
      height: 24px;
    }
  }
}

.chart_container {
  margin: 2rem;
  border: solid 1px #d8d8d8;
  border-radius: 8px;
  box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.03);

  &__heading {
    padding: 1.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    border-bottom: solid 1px #d8d8d8;
  }
}
