.not-connected {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-top: 5rem;
  }

  &__text {
    margin-top: 3rem;
    width: 50rem;
  }

  &__request {
    margin-top: 3rem;
    color: white;
    font-size: 1.8rem;
    // font-weight: bold;
    height: 4rem;
    text-align: center;
    width: 23rem;
    background-color: rgb(89, 89, 89);
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
  }
}
