.company_summary {
  display: flex;
  background-color: #d9e8e3;
  padding: 2.5rem 1.5rem;
  font-size: 1.4rem;

  &__heading {
    font-weight: 500;
    display: block;
  }

  margin-bottom: 4rem;

}

.company_details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  width: 60%;
  box-sizing: content-box;
  border-right: solid 1px #ccc;
}

.company_contact {
  display: flex;
  width: 40%;
  padding-left: 2rem;
  flex-direction: column;
  justify-content: space-between;

  &__icon {
      width: 24px;
      height: 24px;
      margin-top: .5rem;
      margin-right: .5rem;
  }

  &__section {
      display: flex;
  }
}
